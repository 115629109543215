<template>
  <div>
    <b-card no-body :style="'min-height: 750px; color:' + d_menuData.style.color + '; background-color: ' + d_menuData.style.backgroundColor" :class="[d_mainFullScreen ?'parameter-design-full-screen' :'not-parameter-design-full-screen']">
      <b-card-header class="p-1" :style="'color:' + d_menuData.style.main_header.color + '; background-color: ' + d_menuData.style.main_header.backgroundColor">
        <b-row>
          <b-col cols="6">
            <div style="float: left;">
              <img src="@/icon/2933568.png" style="width: 2em; margin-right: 5px; padding: 0px;" />
            </div>
            <div style="float: left;">
              <template v-if="d_selectedLayerIndex !== ''">
                <strong :title="d_layerList[d_selectedLayerIndex].name">
                  {{ d_layerList[d_selectedLayerIndex].name.substring(0, 50) }} ...
                </strong>
              </template>
            </div>
          </b-col>
          <b-col cols="3">
            <template v-if="d_selectedLayerIndex !== ''">
              <span style="color: red;"> [ Katman No: <i>{{ d_layerList[d_selectedLayerIndex].id }}</i>] </span>
              <span style="color: green;"> [<i>{{ d_layerList[d_selectedLayerIndex].layer_wdm_name }}</i>] </span>
              <span style="color: blue;"> [<i>{{ d_layerList[d_selectedLayerIndex].layer_wdm_id }}</i>] </span>
            </template>
          </b-col>
          <b-col cols="3">
            <b-button class="pull-right" size="md" :variant="d_mainFullScreen ? 'warning' : 'white'" :style="d_mainFullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_mainFullScreen ? d_mainFullScreen = false : d_mainFullScreen = true">
              <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
            </b-button>
            <b-button class="pull-right" size="md" variant="white" style="margin-right: 5px; padding: 0px;" @click="f_openLayerList()">
              <img src="@/icon/2504347.png" title="Tam ekran" style="width: 2em;" /> Katman Listesi
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <!-- <w-top-menu :p_menuData="d_menuData"></w-top-menu> -->
      <b-tabs>
        <b-tab @click="d_selectedTab = 'layer_data'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Katman Bilgisi</span>
          </template>
          <template v-if="d_selectedTab === 'layer_data'">
            <b-card no-body :class="[d_fullScreenPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Detaylar
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenPage ? 'warning' : 'white'" :style="d_fullScreenPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenPage ? d_fullScreenPage = false : d_fullScreenPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;" v-if="d_selectedLayerIndex !== ''">
                <b-col cols="12">
                  <b-card no-body>
                    <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Bilgisi
                    </b-card-header>
                    <template v-if="d_layerInformation.data">
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Adı
                        </b-card-header>
                        <b-row style="margin: 0px;">
                          <b-col cols="12"> {{ d_layerInformation.data.label }} </b-col>
                        </b-row>
                      </b-card>
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> İzin Tipi
                        </b-card-header>
                        <b-row style="margin: 0px;">
                          <b-col cols="12"> {{ d_layerInformation.data.data.general.perm.val.label }} </b-col>
                        </b-row>
                      </b-card>
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Yolu
                        </b-card-header>
                        <template v-for="(layer_item, layer_item_ind) in d_layerInformation.data.data.general.layer.list">
                          <b-card no-body>
                            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                              <b-row style="margin: 0px;">
                                <b-col cols="12">
                                  <img v-if="layer_item.layer_type.val.value === 'wdm'" src="@/icon/3201865.png" style="width: 2em;" />
                                  <img v-if="layer_item.layer_type.val.value === 'wdmr'" src="@/icon/2976082.png" style="width: 2em;" />
                                  <strong>{{ layer_item_ind + 1 }} - </strong>
                                  <strong :style="layer_item.layer_type.val.value === 'wdmr' ? 'color: blue;' : 'color: green;'">{{ layer_item.layer_type.val.label }}</strong>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <img src="@/icon/2933568.png" style="width: 2em;" />
                                <span :style="layer_item.layer_type.val.value === 'wdmr' ? 'color: blue;' : 'color: green;'">{{ layer_item.wdm_type.val.label }}</span>
                              </b-col>
                            </b-row>
                            <b-row style="margin: 0px;" v-if="layer_item.layer_name">
                              <b-col cols="12">
                                <img src="@/icon/1661274.png" style="width: 2em;" />
                                <span :style="layer_item.layer_type.val.value === 'wdmr' ? 'color: blue;' : 'color: green;'"> {{ layer_item.layer_name.val.label }} / {{ layer_item.layer_name.val.value }}</span>
                              </b-col>
                            </b-row>
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <img src="@/icon/665778.png" style="width: 2em;" />
                                <span :style="layer_item.layer_type.val.value === 'wdmr' ? 'color: blue;' : 'color: green;'">v{{ layer_item.wdm_version.val.label }}</span>
                              </b-col>
                            </b-row>
                          </b-card>
                        </template>
                      </b-card>
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <b-row style="margin: 0px;">
                            <b-col cols="12">
                              <img src="@/icon/1585563.png" style="width: 2em;" /> Son Güncelleme
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <template v-if="d_layerInformation.data.data.general.last_update_date && d_layerInformation.data.data.general.last_update_date.val">
                              {{ f_dateFormat(d_layerInformation.data.data.general.last_update_date.val) }}
                            </template>
                            <template v-else>
                              ---
                            </template>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <b-row style="margin: 0px;">
                            <b-col cols="12">
                              <img src="@/icon/2751062.png" style="width: 2em;" /> Aynı Katmanlar
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <template v-if="d_layerInformation.data.data.general.similar_layer">
                          <template v-for="(similar_layer, similar_layer_ind) in d_layerInformation.data.data.general.similar_layer.list">
                            <b-card no-body>
                              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                                <b-row style="margin: 0px;">
                                  <b-col cols="12">
                                    <img src="@/icon/2751062.png" style="width: 2em;" />
                                    <strong>{{ similar_layer_ind + 1 }} - </strong> {{ similar_layer.layer_id.val }}
                                  </b-col>
                                </b-row>
                              </b-card-header>
                            </b-card>
                          </template>
                        </template>
                        <template v-else>
                          <b-card no-body>
                            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                              <b-row style="margin: 0px;">
                                <b-col cols="12">
                                  ---
                                </b-col>
                              </b-row>
                            </b-card-header>
                          </b-card>
                        </template>
                      </b-card>
                      <b-card no-body>
                        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          <b-row style="margin: 0px;">
                            <b-col cols="12">
                              <img src="@/icon/1115721.png" style="width: 2em;" /> Katman Ayarları
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <template v-if="d_layerInformation.data && d_layerInformation.data.data && d_layerInformation.data.data.general">
                          <template v-if="d_layerInformation.data.data.general.data_record_layer_type && d_layerInformation.data.data.general.data_record_layer_type.val && d_layerInformation.data.data.general.data_record_layer_type.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.data_record_layer_type.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.data_record_layer_type.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.temporary_data_delete_loop && d_layerInformation.data.data.general.temporary_data_delete_loop.val && d_layerInformation.data.data.general.temporary_data_delete_loop.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.temporary_data_delete_loop.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.temporary_data_delete_loop.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.generate_wdmr_token && d_layerInformation.data.data.general.generate_wdmr_token.val && d_layerInformation.data.data.general.generate_wdmr_token.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.generate_wdmr_token.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.generate_wdmr_token.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.data_record_status && d_layerInformation.data.data.general.data_record_status.val && d_layerInformation.data.data.general.data_record_status.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.data_record_status.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.data_record_status.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.send_delete_mail_type && d_layerInformation.data.data.general.send_delete_mail_type.val && d_layerInformation.data.data.general.send_delete_mail_type.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.send_delete_mail_type.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.send_delete_mail_type.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.temporary_data_minutes && d_layerInformation.data.data.general.temporary_data_minutes.val">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.temporary_data_minutes.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.temporary_data_minutes.val }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.delete_mail_count && d_layerInformation.data.data.general.delete_mail_count.val">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.delete_mail_count.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.delete_mail_count.val }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerSettingsOperation.settings.upper_client_layer_id.eligible && d_layerInformation.data.data.general.upper_client_layer_id && d_layerInformation.data.data.general.upper_client_layer_id.val">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.upper_client_layer_id.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.upper_client_layer_id.val }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.wdmr_token_time && d_layerInformation.data.data.general.wdmr_token_time.val">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.wdmr_token_time.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.wdmr_token_time.val }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.send_layer_data_to_api && d_layerInformation.data.data.general.send_layer_data_to_api.val && d_layerInformation.data.data.general.send_layer_data_to_api.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.send_layer_data_to_api.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.send_layer_data_to_api.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.sending_data_api_type && d_layerInformation.data.data.general.sending_data_api_type.val && d_layerInformation.data.data.general.sending_data_api_type.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_type.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.sending_data_api_type.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.sending_data_api_data_model_sentence_template && d_layerInformation.data.data.general.sending_data_api_data_model_sentence_template.val && d_layerInformation.data.data.general.sending_data_api_data_model_sentence_template.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.sending_data_api_data_model_sentence_template.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.sending_data_api_data_model_parametric_html && d_layerInformation.data.data.general.sending_data_api_data_model_parametric_html.val && d_layerInformation.data.data.general.sending_data_api_data_model_parametric_html.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.sending_data_api_data_model_parametric_html.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.sending_data_api_data_model_parametric_wdmr && d_layerInformation.data.data.general.sending_data_api_data_model_parametric_wdmr.val && d_layerInformation.data.data.general.sending_data_api_data_model_parametric_wdmr.val.label">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.sending_data_api_data_model_parametric_wdmr.val.label }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                          <template v-if="d_layerInformation.data.data.general.api_sending_button_name && d_layerInformation.data.data.general.api_sending_button_name.val">
                            <b-row style="margin: 0px;">
                              <b-col cols="12">
                                <b-form-group :label="d_layerSettingsOperation.settings.api_sending_button_name.label" label-for="label" label-cols="5">
                                  {{ d_layerInformation.data.data.general.api_sending_button_name.val }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                      </b-card>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'recorded_wdmr_list'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Kayıtlar</span>
          </template>
          <template v-if="d_selectedTab === 'recorded_wdmr_list'">
            <b-card no-body :class="[d_fullScreenPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="2">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Detaylar
                  </b-col>
                  <b-col sm="12" lg="10">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenPage ? 'warning' : 'secondary'" :style="d_fullScreenPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenPage ? d_fullScreenPage = false : d_fullScreenPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                    <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_openNewWdmrModal()">
                      <img src="@/icon/109638.png" title="Yeni veri" style="width: 2em;" />
                    </b-button>
                    <template v-if="d_wdmrRecordList.selected_index !== ''">
                      <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_editWdmrData()">
                        <img src="@/icon/3094236.png" title="Düzenle" style="width: 2em;" />
                      </b-button>
                      <b-button class="pull-right" title="Düzenle (yeni sekmede)" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_openNewWebTabModel()">
                        <img src="@/icon/201295.png" style="width: 2em;" />
                        <img src="@/icon/3094236.png" style="width: 2em;" />
                      </b-button>
                      <b-button class="pull-right" title="Göster (yeni sekmede)" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_goToLink(d_wdmrRecordList.url_show_iframe)">
                        <img src="@/icon/201295.png" style="width: 2em;" />
                        <img src="@/icon/680848.png" style="width: 2em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_addParentWdmrInfo()">
                        <img src="@/icon/1705440.png" title="Üst (parent) veri kaydı olarak seç, üst katman bir wdm olduğunda kullanılması gerekmektedir." style="width: 2em;" />
                      </b-button>
                    </template>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="4">
                  <b-card no-body>
                    <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Kayıt Listesi
                      <small style="color: seagreen;">Total: {{ d_wdmrRecordList.total_count }}</small>
                      <!-- <b-button variant="white" class="pull-right" size="md" @click="f_getRecordedWdmrList()">
                        <img src="@/icon/2957896.png" style="width: 2em;" /> verileri listele
                      </b-button> -->
                      <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_wdmrRecordList.total_count" :per-page="d_pagination.perpage"></b-pagination>
                    </b-card-header>
                    <template v-if="d_wdmrRecordList.list && d_wdmrRecordList.list.length > 0">
                      <div id="friend_list" :style="f_calculateBottomTabStyle()">
                        <template v-for="(x, x_ind) in d_wdmrRecordList.list">
                          <b-card :style="d_wdmrRecordList.selected_index === x_ind ? 'background: #9adcd3; margin-bottom: 3px; cursor: pointer;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectWdmrRecord(x_ind)">
                            <b-col cols="12">
                              <div>
                                {{ x_ind + 1 }} - {{ x.label }}
                              </div>
                              <div>
                                <small style="color: blue;">[ {{ x.w_id }} ]</small>
                                <small style="color: blue;">[ {{ x.status }} ]</small>
                                <small style="color: blue;">[ {{ f_dateFormat(x.process_date) }} ]</small>
                                <small v-if="x.date" style="color: blue;">[ {{ f_dateFormat(x.date) }} ]</small>
                              </div>
                            </b-col>
                            <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
                          </b-card>
                        </template>
                      </div>
                    </template>
                  </b-card>
                </b-col>
                <b-col cols="8">
                  <b-tabs>
                    <b-tab @click="d_selectedTabRecordedWdmr = 'wdmr_show'">
                      <template slot="title">
                        <img src="@/icon/665778.png" style="width: 1.5em;">
                        <span :style="'color:' + d_menuData.style.tab_header.color">Veri</span>
                      </template>
                      <template v-if="d_selectedTabRecordedWdmr === 'wdmr_show'">
                        <b-card no-body>
                          <!-- <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                            <b-dropdown variant="white" size="md" class="pull-right" right>
                              <template slot="button-content"> </template>
                              <b-dropdown-item @click="f_editWdmrData()"> düzenle </b-dropdown-item>
                              <b-dropdown-item @click="f_editWdmrData('yes')"> düzenle (yeni sekmede) </b-dropdown-item>
                              <b-dropdown-item @click="f_openNewWdmrModal()"> yeni </b-dropdown-item>
                              <b-dropdown-item @click="f_addParentWdmrInfo()"> üst wdmr kaydı olarak seç </b-dropdown-item>
                            </b-dropdown>
                          </b-card-header> -->
                          <b-row v-if="d_wdmrRecordList.url_show_iframe" :style="f_calculateSubTabStyle()">
                            <b-col sm="12" md="12">
                              <iframe :src="d_wdmrRecordList.url_show_iframe" style="width: 100%; height: 100%;"></iframe>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </b-tab>
                    <b-tab @click="d_selectedTabRecordedWdmr = 'wdmr_record'">
                      <template slot="title">
                        <img src="@/icon/665778.png" style="width: 1.5em;">
                        <span :style="'color:' + d_menuData.style.tab_header.color">Düzenle</span>
                      </template>
                      <template v-if="d_selectedTabRecordedWdmr === 'wdmr_record'">
                        <b-card no-body>
                          <!-- <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                            <b-dropdown variant="white" size="md" class="pull-right" right>
                              <template slot="button-content"> </template>
                              <b-dropdown-item @click="f_editWdmrData()"> düzenle </b-dropdown-item>
                              <b-dropdown-item @click="f_editWdmrData('yes')"> düzenle (yeni sekmede) </b-dropdown-item>
                              <b-dropdown-item @click="f_openNewWdmrModal()"> yeni </b-dropdown-item>
                              <b-dropdown-item @click="f_addParentWdmrInfo()"> üst wdmr kaydı olarak seç </b-dropdown-item>
                            </b-dropdown>
                          </b-card-header> -->
                          <b-row v-if="d_wdmrRecordList.url_record_iframe" :style="f_calculateSubTabStyle()">
                            <b-col sm="12" md="12">
                              <iframe :src="d_wdmrRecordList.url_record_iframe" style="width: 100%; height: 100%;"></iframe>
                            </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'layer_tree'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Katman Ağacı</span>
          </template>
          <template v-if="d_selectedTab === 'layer_tree'">
            <layer-tree :p_treeListData="d_layerTree"></layer-tree>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'wdm'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Katman Veri Modeli</span>
          </template>
          <template v-if="d_selectedTab === 'wdm'">
            <b-card no-body :class="[d_fullScreenPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="2">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Detaylar
                  </b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="3">
                    <!-- <b-form-input class="pull-right" style="width: 100%; margin-right: 5px;" v-model="d_filter.search_text" type="text" placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input> -->
                  </b-col>
                  <b-col sm="12" lg="2">
                    <!-- <span style="margin-top: 10px;">Op. No:</span> -->
                    <!-- <b-form-input class="pull-right" style="width: 100px; margin-right: 5px;" v-model="d_filter.operation_no" type="text" placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input> -->
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenPage ? 'warning' : 'white'" :style="d_fullScreenPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenPage ? d_fullScreenPage = false : d_fullScreenPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row v-if="d_layerWdm && Object.keys(d_layerWdm).length > 0 && d_layerWdm[d_layerList[d_selectedLayerIndex].layer_wdm_id]" style="margin: 0px;">
                <b-col cols="6">
                  <b-card no-body>
                    <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ d_layerWdm[d_layerList[d_selectedLayerIndex].layer_wdm_id].name.label }}
                      <!-- <b-dropdown variant="white" size="md" class="pull-right" right>
                        <template slot="button-content"> </template>
                        <b-dropdown-item @click="f_editSelectedApplicationWdmr()"> düzenle </b-dropdown-item>
                      </b-dropdown> -->
                    </b-card-header>
                    <b-row style="margin: 0px; padding: 5px;">
                      <b-col sm="12" md="12">
                        <wdm-parameters :data_type="d_layerList[d_selectedLayerIndex].layer_wdm_id" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="d_layerWdm" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col cols="6">
                  <b-tabs>
                    <b-tab @click="d_selectedTabParamDetails = 'param_info'">
                      <template slot="title">
                        <img src="@/icon/665778.png" style="width: 1.5em;">
                        <span :style="'color:' + d_menuData.style.tab_header.color">Kayıt Detayları</span>
                      </template>
                      <template v-if="d_selectedTabParamDetails === 'param_info'">
                        <b-card no-body>
                          <!-- <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                          </b-card-header> -->
                          <b-row style="margin: 0px;">
                            <b-col sm="12" lg="6">Parametre</b-col>
                            <b-col sm="12" lg="6"> {{ d_wdmParameters.selected_index_data.index_list.join('.') }} </b-col>
                          </b-row>
                          <b-row style="margin: 0px;">
                            <b-col sm="12" lg="6">Parametre Sorgu </b-col>
                            <b-col sm="12" lg="6"> {{ 'layer' + d_layerList[d_selectedLayerIndex].id + '.' + d_wdmParameters.selected_index_data.index_list.join('.') }} </b-col>
                          </b-row>
                          <b-row style="margin: 0px;">
                            <b-col sm="12" lg="6">Parametre Sorgu SQL </b-col>
                            <b-col sm="12" lg="6"> <span style="color:red;">SELECT</span> {{ 'layer' + d_layerList[d_selectedLayerIndex].id + '.' + d_wdmParameters.selected_index_data.index_list.join('.') }} <span style="color:green;">FROM</span> {{ 'layer' + d_layerList[d_selectedLayerIndex].id }} <span style="color:blue;">limit = 10</span> </b-col>
                          </b-row>
                          <b-row style="margin: 0px;">
                            <b-col sm="12" lg="6">Sistemde Toplam Kayıt</b-col>
                            <b-col sm="12" lg="6"> {{ d_selectedParamInfo.count }} </b-col>
                          </b-row>
                          <b-row style="margin: 0px;" v-if="d_selectedParamInfo.option_id">
                            <b-col sm="12" lg="6">Sistemde Toplam Seçenek Adedi</b-col>
                            <b-col sm="12" lg="6"> {{ d_selectedParamInfo.option_id }} </b-col>
                          </b-row>
                          <b-row style="margin: 0px;" v-if="d_selectedParamInfo.max !== undefined">
                            <b-col sm="12" lg="6">Maksimum Değeri</b-col>
                            <b-col sm="12" lg="6"> {{ d_selectedParamInfo.max }} </b-col>
                          </b-row>
                          <b-row style="margin: 0px;" v-if="d_selectedParamInfo.min !== undefined">
                            <b-col sm="12" lg="6">Minimum Değeri</b-col>
                            <b-col sm="12" lg="6"> {{ d_selectedParamInfo.min }} </b-col>
                          </b-row>
                        </b-card>
                      </template>
                    </b-tab>
                    <b-tab v-if="d_selectedParamInfo.option_id && d_selectedParamInfo.option_id > 0" @click="d_selectedTabParamDetails = 'param_options'">
                      <template slot="title">
                        <img src="@/icon/665778.png" style="width: 1.5em;">
                        <span :style="'color:' + d_menuData.style.tab_header.color">Kayıtlı Seçenekler</span>
                      </template>
                      <template v-if="d_selectedTabParamDetails === 'param_options'">
                        <b-card no-body style="margin-bottom: 0px;">
                          <b-card-header class="p-1" :style="'min-height: 20px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                            Seçenekler
                            <b-button variant="white" class="pull-right" size="md" @click="f_getWdmrParamInfoOptions()">
                              <img src="@/icon/2957896.png" style="width: 2em;" /> seçenekleri getir
                            </b-button>
                          </b-card-header>
                          <template v-if="d_dataset.length > 0">
                            <b-row style="margin: 0px;">
                              <b-col cols="2">
                                Toplam: {{ d_selectedParamInfo.option_id }}
                              </b-col>
                              <b-col cols="3">
                                <b-form-input v-model="d_searchDataSet.text" placeholder="Filtrelemek için yazınız."></b-form-input>
                              </b-col>
                              <b-col cols="2">
                                <span v-if="d_searchText"> Filtre Adedi: {{ d_dataset.length }} </span>
                              </b-col>
                              <b-col cols="5">
                                <template v-if="d_selectedParamInfo.options && d_selectedParamInfo.options.length > d_tablePagination['perpage']">
                                  <b-pagination size="sm" v-model="d_tablePagination['current']" :total-rows="d_searchText ? d_dataset.length : d_selectedParamInfo.option_id" :per-page="d_tablePagination['perpage']" aria-controls="dataset-table"></b-pagination>
                                </template>
                              </b-col>
                            </b-row>
                            <b-row v-if="d_datasetShow" style="margin: 0px;">
                              <b-col cols="12">
                                <div id="case_table" :style="f_dataSetStyle(510)">
                                  <w-table key="data_table" :p_dataset="d_dataset" :p_columnList="d_columnList" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :p_tablePagination="d_tablePagination" :f_selectCell="f_selectCell" :f_dblClickToCell="f_showWTableCellData"></w-table>
                                </div>
                              </b-col>
                            </b-row>
                          </template>
                        </b-card>
                      </template>
                    </b-tab>
                    <b-tab v-if="d_selectedParamData.options && d_selectedParamData.options.length > 0" @click="d_selectedTabParamDetails = 'param_wdm_options'">
                      <template slot="title">
                        <img src="@/icon/665778.png" style="width: 1.5em;">
                        <span :style="'color:' + d_menuData.style.tab_header.color">Wdm Seçenekleri</span>
                      </template>
                      <template v-if="d_selectedTabParamDetails === 'param_wdm_options'">
                        <b-card no-body style="margin-bottom: 0px;">
                          <b-card-header class="p-1" :style="'min-height: 20px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                            Seçenekler
                          </b-card-header>
                          <b-row style="margin: 0px;">
                            <b-col sm="12" lg="3"> <strong> Başlık (label) </strong> </b-col>
                            <b-col sm="12" lg="3"> <strong> Değer (value) </strong> </b-col>
                            <b-col sm="12" lg="6"> <strong> Seçenek Sorgu </strong> <span style="color: blue;"> ( WHERE ) </span> </b-col>
                          </b-row>
                          <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
                            <template v-for="(op, op_ind) in d_selectedParamData.options">
                              <b-row style="margin: 0px;">
                                <b-col sm="12" lg="3"> {{ op.label }} </b-col>
                                <b-col sm="12" lg="3"> {{ op.value }} </b-col>
                                <b-col sm="12" lg="6">
                                  <span> {{ 'layer' + d_layerList[d_selectedLayerIndex].id + '[(' + d_wdmParameters.selected_index_data.index_list.join('.') + ' = ' + op.value + ')]' }} </span>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </b-card>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'client_id'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Veri Sorgula (client_id)</span>
          </template>
          <template v-if="d_selectedTab === 'client_id'">
            <b-card no-body :class="[d_fullScreenPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="2">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Client ID
                  </b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="3">
                    <!-- <b-form-input class="pull-right" style="width: 100%; margin-right: 5px;" v-model="d_filter.search_text" type="text" placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input> -->
                  </b-col>
                  <b-col sm="12" lg="2">
                    <!-- <span style="margin-top: 10px;">Op. No:</span> -->
                    <!-- <b-form-input class="pull-right" style="width: 100px; margin-right: 5px;" v-model="d_filter.operation_no" type="text" placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input> -->
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenPage ? 'warning' : 'white'" :style="d_fullScreenPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenPage ? d_fullScreenPage = false : d_fullScreenPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="6">
                  <b-form-input id="client_id" v-model="d_clientAnalyze.client_id" placeholder="Client Id yazınız."></b-form-input>
                </b-col>
                <b-col cols="6">
                  <b-button @click="f_layerWdmrListByAlgorithm()">sorgula</b-button>
                </b-col>
              </b-row>
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="12">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Sorgu
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <div style="color: blueviolet;">
                    {{ d_clientAnalyze.sql_query }}
                  </div>
                </b-col>
              </b-row>
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="12">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Sonuçlar
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <div style="white-space: pre; border: solid 1px #9ee4f2; box-shadow: -1px -1px 2px 0px #90eebd;">
                    {{ f_getJsonStringify(d_clientAnalyze.result) }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'data_filter_count'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span :style="'color:' + d_menuData.style.tab_header.color">Profil Veri Küme Analizi</span>
          </template>
          <template v-if="d_selectedTab === 'data_filter_count'">
            <b-card no-body :class="[d_fullScreenPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                <b-row>
                  <b-col sm="12" lg="2">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Veri Küme Analizi
                  </b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="3">
                    <!-- <b-form-input class="pull-right" style="width: 100%; margin-right: 5px;" v-model="d_filter.search_text" type="text" placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input> -->
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button size="md" variant="white" @click="f_analyzeAlgorithmRule()" style="width: 100%;">
                      <img src="@/icon/136161.png" title="Tam ekran" style="width: 2em;" /> Analiz Başlat
                    </b-button>
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenPage ? 'warning' : 'white'" :style="d_fullScreenPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenPage ? d_fullScreenPage = false : d_fullScreenPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;" v-if="d_showRuleAlgoritmModal">
                <b-col cols="12">
                  <wdm-rule-a :data_type="d_algorithmRule.data_type" :option_data="d_algorithmRule.option_data" :prepare_data="d_algorithmRule.prepare_data" :query_list="d_algorithmRule.query_list" :compile_str_array="d_algorithmRule.compile_str_array" :compile_str_date="d_algorithmRule.compile_str_date" :wdmr_change="d_algorithmRule.wdmr_change" :p_wdmList="d_algorithmRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_algorithmRule.wps_data" :p_wdmRuleAsettings="d_algorithmRule.wra_settings" :compile_str_text="d_algorithmRule.compile_str_text"></wdm-rule-a>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;" v-if="d_layerQueryAnalyze.sql_query">
                <b-col sm="12" lg="12">
                  <b-card no-body bg-variant="whitey">
                    <b-row style="margin: 0px;">
                      <b-col cols="4" style="padding: 10px;">
                        <strong style="color: #7e0303;"> Kitle Toplam Adet </strong>
                      </b-col>
                      <b-col cols="8" style="padding: 10px; font-size: 16px;">
                        <strong v-if="d_layerQueryAnalyze.result && d_layerQueryAnalyze.result.true_count !== undefined">{{ d_layerQueryAnalyze.result.true_count }}</strong>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;" v-if="d_layerQueryAnalyze.sql_query">
                <b-col sm="12" lg="12">
                  <b-card no-body bg-variant="whitey">
                    <b-row style="margin: 0px;">
                      <b-col cols="4" style="padding: 10px;">
                        <strong style="color: #7e0303;">Sorgu</strong>
                      </b-col>
                      <b-col cols="8" style="padding: 10px; font-size: 16px;">
                        <strong>{{ d_layerQueryAnalyze.sql_query }}</strong>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal v-if="d_showLayerList" title="katman listesi" size="xl" centered class="modal-success" v-model="d_showLayerList" @ok="d_showLayerList = false" ok-variant="success" hide-footer>
      <!-- <b-row>
        <b-col cols="12">
          <b-form-input id="data_model_list" placeholder="Veri modeli filtreleme alanı, ör: wdm23, müşteri formu, ..." v-model="d_searchModels.text"></b-form-input>
        </b-col>
      </b-row> -->
      <b-card header-tag="header" footer-tag="footer" no-body style="margin: 0px;">
        <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
          Katman yetki listesi
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <b-form-input id="user-layer-list" v-model="d_layerSearch.text" placeholder="Filtrelemek için yazınız."></b-form-input>
          </b-col>
        </b-row>
        <template v-for="(layer, layer_ind) in d_layerList">
          <b-row v-if="f_searchLayerFilter(layer)" :key="'layer_' + layer_ind" :style="d_selectedLayerIndex === layer_ind ? 'background-color: #8ee08e; border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px; cursor: pointer;' : 'border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px; cursor: pointer;'">
            <b-col cols="5" @click="f_selectLayer(layer_ind)">
              <strong style="color: blue;"> {{ layer.id }} ) </strong>
              <strong> {{ layer.name }} </strong>
            </b-col>
            <b-col cols="2" @click="f_selectLayer(layer_ind)">
              <small style="color: green;"> [<i>{{ layer.layer_wdm_name }}</i>] </small>
            </b-col>
            <b-col cols="2" @click="f_selectLayer(layer_ind)">
              <small style="color: green;"> [<i>{{ layer.layer_wdm_id }}</i>] </small>
            </b-col>
            <b-col cols="3" @click="f_selectLayer(layer_ind)">
              <small style="color: blue;"> [<i>{{ f_getLayerPermLabel(layer.perm) }}</i>] </small>
            </b-col>
          </b-row>
        </template>
        <!-- <template v-for="(layer_perm, layer_perm_ind) in d_permList.layer_perms">
          <b-row :key="'layer_perm-' + layer_perm_ind" :style="d_selectedLayerIndex === layer_perm_ind ? 'background-color: #8ee08e; border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px; cursor: pointer;' : 'border-bottom: solid 1px #bca3a3; padding: 3px; margin: 0px; cursor: pointer;'">
            <b-col cols="12" @click="f_selectLayer(layer_perm_ind)">
              <strong>
                {{ layer_perm.perm.val.label }}
                <small style="color: green;">[<i>{{ layer_perm.perm.val.value }}</i>]</small>
              </strong>
              <template v-for="(layer, layer_ind) in layer_perm.layer.list">
                <template v-if="layer.layer_type.val.value === 'wdmr'">
                  <div :style="'margin-left:' + (layer_ind * 10) + 'px'">
                    -> <span>{{ layer.layer_name.val.label }}, </span>
                    <span>{{ layer.layer_name.val.value }}, </span>
                    <small :title="layer.wdm_type.val.label">[{{ layer.wdm_type.val.value }}]</small>
                  </div>
                </template>
                <template v-else-if="layer.layer_type.val.value === 'wdm'">
                  <div :style="'margin-left:' + (layer_ind * 10) + 'px'">
                    -> <span>{{ layer.wdm_type.val.label }}, </span>
                    <small>[{{ layer.wdm_type.val.value }}] </small>
                  </div>
                </template>
              </template>
            </b-col>
          </b-row>
        </template> -->
      </b-card>
    </b-modal>
    <b-modal v-if="d_newLayerModal.show" title="katman oluşturma" centered class="modal-success" v-model="d_newLayerModal.show" @ok="d_newLayerModal.show = false" ok-variant="success" hide-footer>
      <div style="min-height: 300px;">
        <b-row style="margin: 0px;">
          <b-col cols="4">
            Wdm seçiniz
          </b-col>
          <b-col cols="8">
            <v-select v-model="d_newLayerModal.selected_wdm" :options="d_permList.wdm_perms"></v-select>
          </b-col>
        </b-row>
        <b-row v-if="d_newLayerModal.type === 'wdm'" style="margin: 0px;">
          <b-col cols="4">
            İzin tipi seçiniz
          </b-col>
          <b-col cols="8">
            <v-select v-model="d_newLayerModal.selected_perm" :options="d_allPermissions"></v-select>
          </b-col>
        </b-row>
        <b-row v-if="d_newLayerModal.selected_wdm && (d_newLayerModal.type === 'wdmr' || (d_newLayerModal.type === 'wdm' && d_newLayerModal.selected_perm))" style="margin: 0px;">
          <b-col cols="6">
          </b-col>
          <b-col cols="6">
            <div style="padding: 5px;">
              <b-button class="pull-right" size="md" variant="secondary" @click="f_addNewLayer()">
                <img src="@/icon/1886795.png" style="width: 2em;" /> yeni katmanı ekle
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal v-if="d_showWTableCellData.show" v-model="d_showWTableCellData.show" size="xl" title="Hücre Değeri" centered scrollable hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="12">
          {{ d_showWTableCellData.text }}
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <!--  -->
    <!--  -->
    <b-modal v-if="d_wisdomData.show" v-model="d_wisdomData.show" centered class="modal-success" @ok="d_wisdomData.show = false" ok-variant="success" hide-footer size="lg">
      <iframe :src="d_wisdomData.url_iframe" style="width: 100%; min-height: 500px;"></iframe>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button type="button" variant="danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</b-button>
          <button type="button" class="btn btn-success" v-on:click="f_completeWdmrSave()"> İşlemi tamamla </button>
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <!--  -->
    <!--  -->
    <b-modal v-if="d_layerDataRecord.show" v-model="d_layerDataRecord.show" centered class="modal-success" @ok="d_layerDataRecord.show = false" ok-variant="success" hide-footer size="lg">
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Bilgisi
        </b-card-header>
        <template v-if="d_layerDataRecord.layer_data">
          <b-card no-body>
            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
              <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> İzin Tipi
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col cols="6">
                <img src="@/icon/719135.png" style="width: 2em;" /> Katman izin tipi
              </b-col>
              <b-col cols="6">
                <b-form-select v-model="d_layerDataRecord.layer_data.perm">
                  <option v-for="(opt, opt_ind) in d_allPermissions" :value="opt.value">{{ opt.label }}</option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body>
            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
              <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> Katman Yolu
              <button type="button" class="btn btn-success pull-right" v-on:click="f_addNewLayerItem()"> + yeni </button>
            </b-card-header>
            <template v-for="(layer_item, layer_item_ind) in d_layerDataRecord.layer_data.layer_list">
              <b-card no-body>
                <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                  <b-row style="margin: 0px;">
                    <b-col cols="6">
                      <img v-if="layer_item.layer_type === 'wdm'" src="@/icon/3201865.png" style="width: 2em;" />
                      <img v-if="layer_item.layer_type === 'wdmr'" src="@/icon/2976082.png" style="width: 2em;" />
                      <strong>{{ layer_item_ind + 1 }} - </strong>
                      <strong :style="layer_item.layer_type === 'wdmr' ? 'color: blue;' : 'color: green;'">Katman Tipi</strong>
                    </b-col>
                    <b-col cols="4">
                      <b-form-select v-model="layer_item.layer_type">
                        <option value="wdm">wdm</option>
                        <option value="wdmr">wdmr</option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="2">
                      <button type="danger" class="btn btn-success pull-right" v-on:click="f_deleteLayerItem(layer_item_ind)">sil</button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-row style="margin: 0px;">
                  <b-col cols="12">
                    <img src="@/icon/2933568.png" style="width: 2em;" />
                    <b-row style="margin: 0px;">
                      <b-col cols="6">
                        <img src="@/icon/1661274.png" style="width: 2em;" />
                        <span :style="layer_item.layer_type === 'wdmr' ? 'color: blue;' : 'color: green;'">Wdm Tipi</span>
                      </b-col>
                      <b-col cols="6">
                        <b-form-select v-model="layer_item.wdm_type">
                          <template v-for="(wdm_item, wdm_item_ind) in d_userWdmList.list">
                            <option :value="wdm_item.value">{{ wdm_item.label }} / {{ wdm_item.value }} </option>
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row style="margin: 0px;">
                      <b-col cols="6">
                        <img src="@/icon/665778.png" style="width: 2em;" />
                        <span :style="layer_item.layer_type === 'wdmr' ? 'color: blue;' : 'color: green;'">Wdm Version</span>
                      </b-col>
                      <b-col cols="6">
                        <b-form-select v-model="layer_item.wdm_version">
                          <template v-for="(wdm_item, wdm_item_ind) in d_userWdmList.list">
                            <template v-if="wdm_item.value === layer_item.wdm_type">
                              <template v-for="(version, version_ind) in wdm_item.list">
                                <option :value="version.version">{{ version.version }}</option>
                              </template>
                            </template>
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row v-if="layer_item.layer_type === 'wdmr'" style="margin: 0px;">
                      <b-col cols="6">
                        <img src="@/icon/665778.png" style="width: 2em;" />
                        <span :style="layer_item.layer_type === 'wdmr' ? 'color: blue;' : 'color: green;'">Wdmr ID</span>
                      </b-col>
                      <b-col cols="6">
                        <b-form-input v-model="layer_item.wdmr_id"></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-card>
          <b-card no-body>
            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <img src="@/icon/2751062.png" style="width: 2em;" /> Aynı Katmanlar
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="d_layerDataRecord.layer_data.similar_layer">
              <template v-for="(similar_layer, similar_layer_ind) in d_layerDataRecord.layer_data.similar_layer">
                <b-card no-body>
                  <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                    <b-row style="margin: 0px;">
                      <b-col cols="12">
                        <img src="@/icon/2751062.png" style="width: 2em;" />
                        <strong>{{ similar_layer_ind + 1 }} - </strong> {{ similar_layer.layer_id }}
                      </b-col>
                    </b-row>
                  </b-card-header>
                </b-card>
              </template>
            </template>
            <template v-else>
              <b-card no-body>
                <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
                  <b-row style="margin: 0px;">
                    <b-col cols="12">
                      ---
                    </b-col>
                  </b-row>
                </b-card-header>
              </b-card>
            </template>
          </b-card>
        </template>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button type="button" variant="danger" @click="d_layerDataRecord.show = false">{{ $t('wdm16.4053') }}</b-button>
          <button type="button" class="btn btn-success" v-on:click="f_saveLayerData()">Katman Verisini kaydet</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_searchUser.show" v-model="d_searchUser.show" size="xl" title="Kullanıcı Katman Atama" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <strong style="color: blue;">
          {{ d_layerList[d_selectedLayerIndex].name }}
        </strong>
        <b-button variant="success" @click="f_updateLayerUserPermissions()">katman kullanıcılarını güncelle</b-button>
      </b-card>
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              Kullanıcı sorgulama
            </b-col>
          </b-row>
        </b-card-header>
        <b-row style="margin: 0px;">
          <b-col cols="10">
            <b-form-input v-model="d_searchUser.search_text"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-button v-if="d_searchUser.search_text" variant="success" @click="f_searchUserList()">sorgula</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              Kullanıcı listesi
            </b-col>
          </b-row>
        </b-card-header>
        <div style="overflow-x: hidden; overflow-y: auto; height: 300px;">
          <template v-for="(user, user_ind) in d_searchUser.list">
            <b-row id="user-search-list-row" style="margin: 0px; cursor: pointer; border-bottom: solid 1px black;" @click="f_addToUserList(user)">
              <b-col cols="12">
                {{ user.id }} / {{ user.username }} / {{ user.first_name }} / {{ user.last_name }} [ {{ user.email }} ]
              </b-col>
            </b-row>
          </template>
        </div>
      </b-card>
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              Seçilen Kullanıcılar
            </b-col>
          </b-row>
        </b-card-header>
        <template v-for="(user, user_ind) in d_searchUser.selected_user_list">
          <b-row id="user-search-list-selected-row" style="margin: 0px; cursor: pointer;" @click="f_deleteUserFromSelected(user_ind)">
            <b-col cols="12">
              {{ user.id }} / {{ user.username }} / {{ user.first_name }} / {{ user.last_name }} [ {{ user.email }} ]
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-modal>
    <b-modal v-if="d_newWdmrModal.show" v-model="d_newWdmrModal.show" size="lg" title="Yeni veri kayıt oluşturma ekranı" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              Yeni Veri (WDMR)
            </b-col>
          </b-row>
        </b-card-header>
        <b-card no-body v-if="d_selectedParentWdmrInfo.id">
          <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
            <b-row style="margin: 0px;">
              <b-col cols="12">
                Üst ilişkili kayıtlı veri ID
              </b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              {{ d_selectedParentWdmrInfo.layer_data.label }} / {{ d_selectedParentWdmrInfo.layer_data.id }}
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <b-form-input v-model="d_selectedParentWdmrInfo.id"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body>
          <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
            <b-row style="margin: 0px;">
              <b-col cols="12">
                Veri başlığı
              </b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <b-form-input v-model="d_newWdmrModal.label"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_addNewWdmrToThisLayer()">yeni kayıt oluştur</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_layerSettingsOperation.show" v-model="d_layerSettingsOperation.show" size="lg" title="Katman Ayarları" centered scrollable hide-footer header-bg-variant="primary">
      <b-card no-body>
        <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              {{ d_layerList[d_selectedLayerIndex].name }}
            </b-col>
          </b-row>
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.data_record_layer_type.label" label-for="label" label-cols="6">
              <b-form-select v-model="d_layerSettingsOperation.settings.data_record_layer_type.selected">
                <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.data_record_layer_type.options" :value="op.value"> {{ op.label }} </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.data_record_status.label" label-for="label" label-cols="6">
              <b-form-select v-model="d_layerSettingsOperation.settings.data_record_status.selected">
                <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.data_record_status.options" :value="op.value"> {{ op.label }} </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="d_layerSettingsOperation.settings.data_record_layer_type.selected === 'temporary'">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.temporary_data_delete_loop.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.temporary_data_delete_loop.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.temporary_data_delete_loop.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.send_delete_mail_type.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.send_delete_mail_type.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.send_delete_mail_type.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.delete_mail_count.label" label-for="label" label-cols="6">
                <!-- <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input> -->
                <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.delete_mail_count.selected"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.temporary_data_minutes.label" label-for="label" label-cols="6">
                <!-- <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input> -->
                <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.temporary_data_minutes.selected"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_layerSettingsOperation.settings.data_record_layer_type.selected === 'cleanup'">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.temporary_data_delete_loop.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.temporary_data_delete_loop.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.temporary_data_delete_loop.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.send_delete_mail_type.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.send_delete_mail_type.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.send_delete_mail_type.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.delete_mail_count.label" label-for="label" label-cols="6">
                <!-- <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input> -->
                <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.delete_mail_count.selected"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <b-row v-if="d_layerSettingsOperation.settings.upper_client_layer_id.eligible">
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.upper_client_layer_id.label" label-for="label" label-cols="6">
              <!-- <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input> -->
              <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.upper_client_layer_id.selected"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.generate_wdmr_token.label" label-for="label" label-cols="6">
              <b-form-select v-model="d_layerSettingsOperation.settings.generate_wdmr_token.selected">
                <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.generate_wdmr_token.options" :value="op.value"> {{ op.label }} </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="d_layerSettingsOperation.settings.generate_wdmr_token.selected === 'yes'">
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.wdmr_token_time.label" label-for="label" label-cols="6">
              <!-- <b-form-input type="text" id="label" v-model="parent_data.label"></b-form-input> -->
              <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.wdmr_token_time.selected"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="d_layerSettingsOperation.settings.send_layer_data_to_api.label" label-for="label" label-cols="6">
              <b-form-select v-model="d_layerSettingsOperation.settings.send_layer_data_to_api.selected">
                <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.send_layer_data_to_api.options" :value="op.value"> {{ op.label }} </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="d_layerSettingsOperation.settings.send_layer_data_to_api.selected === 'yes'">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_type.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.sending_data_api_type.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.sending_data_api_type.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.label" label-for="label" label-cols="6">
                <b-form-select v-model="d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.selected">
                  <option v-for="(op, op_ind) in d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.options" :value="op.value"> {{ op.label }} </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="d_layerSettingsOperation.settings.api_sending_button_name.label" label-for="label" label-cols="6">
                <b-form-input type="text" id="label" v-model="d_layerSettingsOperation.settings.api_sending_button_name.selected"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_layerSettingsOperation()">ayarları güncelle</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_wdmrEditNewWebTab.show" v-model="d_wdmrEditNewWebTab.show" size="md" title="Wdmr Argümanları ve linki düzenleme" centered scrollable hide-footer header-bg-variant="info">
      <b-card no-body>
        <b-row>
          <b-col cols="6">
            <strong>Model ID:</strong>
          </b-col>
          <b-col cols="6">
            <b-form-input v-model="d_wdmrEditNewWebTab.model_id"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <strong>Şema ID:</strong>
          </b-col>
          <b-col cols="6">
            <b-form-input v-model="d_wdmrEditNewWebTab.schema_id"></b-form-input>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_editWdmrData('yes')">yeni sekmede aç</button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ClsRule } from '@/services/public/rule';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as WTable
} from '@/components/widgets/WTable';

import {
  default as LayerTree
} from '@/components/widgets/LayerTree';

import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from "moment";
import WTopMenu from '@/components/widgets/WTopMenu';
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import WmanagerService from '@/services/wmanager';
import WdmService from '@/services/wdm';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
  name: 'LayerWdmrRecordView',
  components: {
    Modal,
    WebBottom,
    WTopMenu,
    vSelect,
    WisdomDataModal,
    WdmParameters,
    WTable,
    LayerTree,
    WdmRuleA
  },
  computed: mapGetters({
    help: 'help',
    device: 'device'
  }),
  data () {
    return {
      d_layerQueryAnalyze: { 'show': true, 'result': '', 'sql_query': '' },
      d_algorithmRuleNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'compile_str_date': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_algorithmRule: {},
      d_showRuleAlgoritmModal: false,
      d_layerOptionDataList: {},
      d_layerWdmList: [],
      d_layerTree: {
        'list': []
      },
      d_clientAnalyze: {
        'client_id': '',
        'result': {}
      },
      d_wdmrEditNewWebTab: {
        'show': false,
        'model_id': '',
        'schema_id': ''
      },
      d_layerSettingsOperation: {
        'show': false,
        'settings': {
          'data_record_layer_type': {
            'label': 'Katman veri kayıt tipi',
            'selected': '',
            'options': [
              { 'label': 'kalıcı', 'value': 'persistent' },
              { 'label': 'geçici', 'value': 'temporary' },
              { 'label': 'temizle', 'value': 'cleanup' }
            ]
          },
          'temporary_data_delete_loop': {
            'label': 'Geçici veri silme döngüsü',
            'selected': '',
            'options': [
              { "value": "go_back_to_the_beginning", "label": "tekrar başa dönerek devam et" },
              { "value": "do_not_go_back", "label": "başa dönme" }
            ]
          },
          'generate_wdmr_token': {
            'label': 'Wdmr Dokümanlarına Token Üretimi',
            'selected': '',
            'options': [{ "value": "yes", "label": "evet" }, { "value": "no", "label": "hayır" }]
          },
          'data_record_status': {
            'label': 'Veri Kayıt Durumu',
            'selected': '',
            'options': [{ "value": "active", "label": "aktif" }, { "value": "passive", "label": "pasif" }]
          },
          'delete_mail_count': {
            'label': 'Silme işlemi sırasında her X silindiğinde mail gönder',
            'selected': '',
          },
          'temporary_data_minutes': {
            'label': 'Geçici veri süresi (dakika)',
            'selected': '',
          },
          'upper_client_layer_id': {
            'eligible': false,
            'label': 'Parent client katman ID',
            'selected': '',
          },
          'wdmr_token_time': {
            'label': 'Wdmr Token Süresi (Dakika)',
            'selected': '',
          },
          'send_delete_mail_type': {
            'label': 'Silme işlemi mail gönderim tipi',
            'selected': '',
            'options': [
              { "value": "dont_send", "label": "mail gönderme" },
              { "value": "send_mail_start_continuing_end", "label": "başlangıç, devam ederken, sonlandığında" },
              { "value": "send_mail_continuing_end", "label": "devam ederken, sonlandığında" },
              { "value": "send_mail_end", "label": "sonlandığında" }
            ]
          },
          'send_layer_data_to_api': {
            'label': 'Katmana kaydedilen veriler dış apiye gönderilsin mi ?',
            'selected': '',
            'options': [{ "value": "yes", "label": "evet" }, { "value": "no", "label": "hayır" }]
          },
          'sending_data_api_type': {
            'label': 'Dış apiye gönderim şekli',
            'selected': '',
            'options': [{ "value": "every_time_it_is_updated", "label": "her güncellendiğinde" }, { "value": "use_api_sending_button", "label": "api gönderim butonu ile" }]
          },
          'sending_data_api_data_model_sentence_template': {
            'label': 'Dış apiye gönderim cümle kalıpları veri modeli',
            'selected': '',
            'options': [{ "value": "yes", "label": "evet" }, { "value": "no", "label": "hayır" }]
          },
          'sending_data_api_data_model_parametric_html': {
            'label': 'Dış apiye gönderim parametrik html veri modeli',
            'selected': '',
            'options': [{ "value": "yes", "label": "evet" }, { "value": "no", "label": "hayır" }]
          },
          'sending_data_api_data_model_parametric_wdmr': {
            'label': 'Dış apiye gönderim parametrik wdmr veri modeli',
            'selected': '',
            'options': [{ "value": "yes", "label": "evet" }, { "value": "no", "label": "hayır" }]
          },
          'api_sending_button_name': {
            'label': 'Dış apiye gönderim butonu adı',
            'selected': '',
          },
        }
      },
      d_newWdmrModal: { 'show': false, 'label': '' },
      d_selectedParentWdmrInfo: { 'id': '', 'layer_info': '' },
      d_searchUser: { 'list': [], 'selected_user_list': [], 'show': false, 'search_text': '' },
      d_optionData: {},
      d_userWdmList: { 'list': [], 'selected_index': '' },
      d_layerDataRecord: { 'show': false, 'layer_data': '' },
      d_layerInformation: { 'data': '' },
      d_wisdomData: {
        'wdmr_id': '',
        'show': false,
        'wisdom_op': '', // edit or new
        'url_iframe': ''
      },
      WDMR_LINKS: require('@/config/index').WDMR_LINKS,
      d_wdmrToken: { 'access_token': '', 'refresh_token': '' },
      d_wdmrRecordList: {
        'url_record_iframe': '',
        'url_show_iframe': '',
        'data': '',
        'show': false,
        'list': [],
        'selected_index': '',
        'total_count': 0
      },
      d_pagination: {
        'start_type': 'begin', // end or begin
        'start': 0,
        'end': 49,
        'current': 0,
        'perpage': 50
      },
      d_selectedParamData: {},
      d_dataset: [],
      d_fullScreenPage: false,
      d_layerSearch: { 'text': '' },
      d_totalRowCount: 0,
      d_datasetShow: true,
      d_searchText: '',
      d_searchDataSet: { 'timeout': '', 'text': '' },
      d_showWTableCellData: { 'text': '', 'show': false },
      d_selectedCells: [],
      d_columnList: [{ 'value': 'label', 'label': 'Adı' }, { 'value': 'count', 'label': 'Adet' }],
      d_tablePagination: { 'current': 1, 'perpage': 100, 'start': 0, 'end': 100 },
      d_selectedTabParamDetails: 'param_info',
      d_selectedTabRecordedWdmr: 'wdmr_show',
      d_selectedParamInfo: '',
      d_wdmParameters: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      },
      d_wdmParametersUserWdm: {
        'selection': {
          'times': 'one', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      },
      d_selectedTab: 'layer_data',
      d_layerWdm: {},
      d_layerList: [],
      d_allPermissions: [
        { 'label': 'Katman mimarisi oluşturabilme (alt katmanlar)', 'value': 'can_design_wdm_architecture' },
        { 'label': 'Veri modeli ekleme düzenleme', 'value': 'can_add_edit_wdm' },
        { 'label': 'veri görüntüleme', 'value': 'can_view_wdm' }
      ],
      d_newLayerModal: {
        'show': false,
        'type': '',
        'selected_wdm': '',
        'selected_perm': ''
      },
      d_showLayerList: false,
      d_selectedLayerIndex: '',
      d_permList: '',
      d_wdm22: {},
      d_showTree: false,
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'list': [],
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_37',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      },
      d_mainFullScreen: false,
      d_menuData: {
        'selected_menu': 'general', // your defined menu value below
        'style': {
          'height': '150',
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('../../icon/2979152.png'),
          'value': 'general',
          'list': [{
              'icon': '',
              'label': 'Genel',
              'value': 'general',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': this.$t('wdm16.11299'),
                'icon': require('../../icon/2504347.png'),
                'label': '',
                'value': 'general',
                'click': this.f_openLayerList
              }]
            }
            /*, {
                        'icon': '',
                        'label': 'Düzenle',
                        'value': 'edit',
                        'list': [{
                          'type': 'button', // button, buttongroup, color
                          'title': this.$t('wdm16.4061'), // save
                          'icon': require('../../icon/2087726.png'),
                          'label': '',
                          'value': 'save_wdm',
                          'click': this.f_yourFunction
                        }]
                      }*/
          ]
        }, {
          'label': 'Katman',
          'icon': require('../../icon/2393061.png'),
          'value': 'layer',
          'list': [{
              'icon': '',
              'label': 'Genel',
              'value': 'general',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'seçili katmanı kopyalayarak yeni katman oluşturma ekranını aç',
                'icon': require('../../icon/1705440.png'),
                'label': 'Katmanı kopyala',
                'value': 'copyselectedlayerfornewlayer',
                'click': this.f_copySelectedLayerForNewLayer
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'seçili katmana yeni wdmr katmanı ekleme işlemi',
                'icon': require('../../icon/1910880.png'),
                'label': 'Katman düzenleme ekranını aç',
                'value': 'openlayereditmodal',
                'click': this.f_openLayerRecordModal
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'seçili katman ayarlarını düzenleme',
                'icon': require('../../icon/1910880.png'),
                'label': 'Katman ayarları',
                'value': 'openlayersettingsoperationmodal',
                'click': this.f_openLayerSettingsOperationModal
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'seçili katmanı kullanıcıya atama işlemi',
                'icon': require('../../icon/1910880.png'),
                'label': 'Kullanıcı & katman yetkilendirme',
                'value': 'openlayeruserassignmodal',
                'click': this.f_openLayerUserAssignModal
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'seçili katmana ait veri modelini (wdm) resetle',
                'icon': require('../../icon/1910880.png'),
                'label': 'Katman Wdm Resetleme',
                'value': 'layerwdmupdate',
                'click': this.f_layerWdmUpdate
              }]
            }
            /*, {
                        'icon': '',
                        'label': 'Düzenle',
                        'value': 'edit',
                        'list': [{
                          'type': 'button', // button, buttongroup, color
                          'title': this.$t('wdm16.4061'), // save
                          'icon': require('../../icon/2087726.png'),
                          'label': '',
                          'value': 'save_wdm',
                          'click': this.f_yourFunction
                        }]
                      }*/
          ]
        }, {
          'label': 'Görünüm',
          'icon': require('../../icon/2956643.png'),
          'value': 'view',
          'list': [{
              'icon': require('../../icon/2956643.png'),
              'label': 'Genel',
              'value': 'general',
              'list': []
            }
            /*, {
                        'icon': require('../../icon/719135.png'),
                        'label': 'Veri Modeli',
                        'value': 'datamodel',
                        'list': [{
                          'title': this.$t('wdm16.11320'),
                          'type': 'button', // button, buttongroup, color
                          'icon': require('../../icon/719135.png'),
                          'label': 'Wdm tasarım ekranı',
                          'value': 'wdmstylemodal',
                          'click': this.f_yourFunction
                        }]
                      }*/
          ]
        }, {
          'label': 'Ayarlar',
          'icon': require('../../icon/1584256.png'),
          'value': 'settings',
          'list': [
            /*{
                        'icon': require('../../icon/1584256.png'),
                        'label': 'Genel',
                        'value': 'general',
                        'list': [{
                          'type': 'button', // button, buttongroup, color
                          'title': this.$t('wdm16.11304'),
                          'icon': require('../../icon/2940373.png'),
                          'label': this.$t('wdm16.11304'),
                          'value': 'wdmuserpermission',
                          'click': this.f_yourFunction
                        }]
                      }*/
          ]
        }, {
          'label': 'Yardım',
          'icon': require('../../icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('../../icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      }
    };
  },
  created: function () {
    this.d_wdm22 = JSON.parse(localStorage.getItem('wdm22'));
    if (this.d_wdm22 && this.d_wdm22.id) {
      this.f_getUserLayerList();
      this.f_getUserWdmList();
    }
    // this.f_getWdmrTreeList();
  },
  mounted () {
    this.d_algorithmRule = JSON.parse(JSON.stringify(this.d_algorithmRuleNew));
    this.d_wdmrToken = JSON.parse(localStorage.getItem('wdmr_token'));
  },
  destroyed () {},
  methods: {
    f_analyzeAlgorithmRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_algorithmRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_algorithmRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_algorithmRule.query_list, this.d_algorithmRule.compile_str_array, this.d_algorithmRule.wps_data);
        }
        let data = {
          'wdm_type': 'LayerWdm_To_LayerId',
          'wdm_type_usage_for_starter': 1,
          'rule_data': {
            'query_list': this.d_algorithmRule.query_list,
            'compile_str_array': this.d_algorithmRule.compile_str_array,
            'compile_str_text': this.d_algorithmRule.compile_str_text,
            'compile_str_date': this.d_algorithmRule.compile_str_date
          },
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        WmanagerService.rule_case_algorithm_get(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status_code === '3000') {
              console.log(resp.data);
              if (resp.data.case_algorithm) {
                let sql_query = "SELECT count() FROM layer" + this.d_layerList[this.d_selectedLayerIndex].id + ' WHERE ' + resp.data.case_algorithm;
                console.log("sql_query : ", sql_query);
                let data = {
                  'sql': sql_query
                };
                this.d_layerQueryAnalyze.sql_query = sql_query;
                this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sorgu çalıştırılıyor. Lütfen bekleyiniz.' } });
                WmanagerService.layer_wdmr_list_by_algorithm(data)
                  .then(resp => {
                    this.$store.commit('Loading', { 'status': false, 'data': {} });
                    if (resp.data.status_code === '3000') {
                      this.d_layerQueryAnalyze.result = resp.data;
                    } else {
                      alert(resp.data.status_code + ' / ' + resp.data.status_message);
                    }
                  });
              }
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Profillenmiş veri analiz işlemi' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_prepareAlgorithmRule: function () {
      this.d_showRuleAlgoritmModal = false;
      this.d_algorithmRule = JSON.parse(JSON.stringify(this.d_algorithmRuleNew));
      this.d_layerWdmList = [];
      this.d_layerOptionDataList = {};
      let main_layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let query = 'layer_id=' + this.d_layerList[this.d_selectedLayerIndex].id;
      console.log("get_sub_layer_wdm_list , query : ", query)
      WmanagerService.get_sub_layer_wdm_list(query)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            for (let layer_index in resp.data.list) {
              let lbl_splitted = resp.data.list[layer_index].label.split('->');
              let new_layer_item = {
                'label': lbl_splitted[lbl_splitted.length - 1],
                'value': resp.data.list[layer_index].value,
                'bucket': resp.data.list[layer_index].bucket,
                'type': resp.data.list[layer_index].type
              }
              let data_type = resp.data.list[layer_index].type;
              this.d_layerWdmList.push(new_layer_item);
              this.d_layerOptionDataList[data_type] = resp.data.list[layer_index].wdm_data;
            }
            this.d_algorithmRule.wdm_list = this.d_layerWdmList;
            this.d_algorithmRule.option_data = this.d_layerOptionDataList;
            this.d_showRuleAlgoritmModal = true;
          } else {
            let msg = resp.data.message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'error' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_layerWdmrListByAlgorithm: function () {
      let sql_query = 'SELECT layer' + this.d_layerList[this.d_selectedLayerIndex].id + ' FROM layer' + this.d_layerList[this.d_selectedLayerIndex].id + '[client_id in ' + this.d_clientAnalyze.client_id + ']';
      let data = {
        'sql': sql_query
      };
      this.d_clientAnalyze.sql_query = sql_query;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sorgu çalıştırılıyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_wdmr_list_by_algorithm(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.d_clientAnalyze.result = resp.data;
          if (resp.data.status_code === '3000') {
            //
          } else {
            alert(resp.data.status_code + ' / ' + resp.data.status_message);
          }
        });
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_openNewWebTabModel: function () {
      this.d_wdmrEditNewWebTab.show = true;
    },
    f_searchLayerFilter: function (layer) {
      if (this.d_layerSearch.text) {
        if (layer.name.toLocaleLowerCase().indexOf(this.d_layerSearch.text) !== -1) {
          return true;
        } else {
          for (let i in this.d_allPermissions) {
            if (this.d_allPermissions[i].value === layer.perm) {
              if (this.d_allPermissions[i].label.toLocaleLowerCase().indexOf(this.d_layerSearch.text) !== -1) {
                return true;
              }
              break;
            }
          }
        }
      } else {
        return true;
      }
      return false;
    },
    f_getLayerPermLabel: function (perm_value) {
      for (let i in this.d_allPermissions) {
        if (perm_value === this.d_allPermissions[i].value) {
          return this.d_allPermissions[i].label;
        }
      }
    },
    f_layerWdmUpdate: function () {
      let wdm_update_type = 'reset';
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let query = 'layer_id=' + layer_id + '&wdm_update_type=' + wdm_update_type;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman wdm i resetleniyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_wdm_update(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            console.log(resp.data);
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_updateLayerUserPermissions: function () {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let query = 'layer_id=' + layer_id;
      let data = {
        'user_assign_list': this.d_searchUser.selected_user_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman kullanıcıları güncelleniyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_user_assign(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            console.log(resp.data);
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_openLayerSettingsOperationModal: function () {
      if (this.d_selectedLayerIndex !== '') {
        let layer_data = this.d_layerInformation.data;
        let is_upper_client_layer_id_eligible = false;
        if (layer_data && layer_data.data && layer_data.data.general) {
          let item_count_for_wdm_type = 0;
          for (let i in layer_data.data.general.layer.list) {
            if (layer_data.data.general.layer.list[i].layer_type.val.value === 'wdm') {
              item_count_for_wdm_type += 1;
            }
          }
          if (item_count_for_wdm_type > 1) {
            is_upper_client_layer_id_eligible = true;
          }
          if (layer_data.data.general.data_record_layer_type && layer_data.data.general.data_record_layer_type.val && layer_data.data.general.data_record_layer_type.val.value) {
            this.d_layerSettingsOperation.settings.data_record_layer_type.selected = layer_data.data.general.data_record_layer_type.val.value;
          } else {
            this.d_layerSettingsOperation.settings.data_record_layer_type.selected = 'persistent';
          }
          if (layer_data.data.general.temporary_data_delete_loop && layer_data.data.general.temporary_data_delete_loop.val && layer_data.data.general.temporary_data_delete_loop.val.value) {
            this.d_layerSettingsOperation.settings.temporary_data_delete_loop.selected = layer_data.data.general.temporary_data_delete_loop.val.value;
          } else {
            this.d_layerSettingsOperation.settings.temporary_data_delete_loop.selected = '';
          }
          if (layer_data.data.general.generate_wdmr_token && layer_data.data.general.generate_wdmr_token.val && layer_data.data.general.generate_wdmr_token.val.value) {
            this.d_layerSettingsOperation.settings.generate_wdmr_token.selected = layer_data.data.general.generate_wdmr_token.val.value;
          } else {
            this.d_layerSettingsOperation.settings.generate_wdmr_token.selected = 'no';
          }
          if (layer_data.data.general.data_record_status && layer_data.data.general.data_record_status.val && layer_data.data.general.data_record_status.val.value) {
            this.d_layerSettingsOperation.settings.data_record_status.selected = layer_data.data.general.data_record_status.val.value;
          } else {
            this.d_layerSettingsOperation.settings.data_record_status.selected = 'active';
          }
          if (layer_data.data.general.delete_mail_count && layer_data.data.general.delete_mail_count.val !== undefined) {
            this.d_layerSettingsOperation.settings.delete_mail_count.selected = layer_data.data.general.delete_mail_count.val;
          } else {
            this.d_layerSettingsOperation.settings.delete_mail_count.selected = '';
          }
          if (layer_data.data.general.temporary_data_minutes && layer_data.data.general.temporary_data_minutes.val !== undefined) {
            this.d_layerSettingsOperation.settings.temporary_data_minutes.selected = layer_data.data.general.temporary_data_minutes.val;
          } else {
            this.d_layerSettingsOperation.settings.temporary_data_minutes.selected = '';
          }
          if (is_upper_client_layer_id_eligible) {
            this.d_layerSettingsOperation.settings.upper_client_layer_id.eligible = true;
            if (layer_data.data.general.upper_client_layer_id && layer_data.data.general.upper_client_layer_id.val !== undefined) {
              this.d_layerSettingsOperation.settings.upper_client_layer_id.selected = layer_data.data.general.upper_client_layer_id.val;
            } else {
              this.d_layerSettingsOperation.settings.upper_client_layer_id.selected = '';
            }
          } else {
            this.d_layerSettingsOperation.settings.upper_client_layer_id.eligible = false;
          }
          if (layer_data.data.general.wdmr_token_time && layer_data.data.general.wdmr_token_time.val !== undefined) {
            this.d_layerSettingsOperation.settings.wdmr_token_time.selected = layer_data.data.general.wdmr_token_time.val;
          } else {
            this.d_layerSettingsOperation.settings.wdmr_token_time.selected = '';
          }
          if (layer_data.data.general.send_delete_mail_type && layer_data.data.general.send_delete_mail_type.val && layer_data.data.general.send_delete_mail_type.val.value) {
            this.d_layerSettingsOperation.settings.send_delete_mail_type.selected = layer_data.data.general.send_delete_mail_type.val.value;
          } else {
            this.d_layerSettingsOperation.settings.send_delete_mail_type.selected = '';
          }
          if (layer_data.data.general.send_layer_data_to_api && layer_data.data.general.send_layer_data_to_api.val && layer_data.data.general.send_layer_data_to_api.val.value) {
            this.d_layerSettingsOperation.settings.send_layer_data_to_api.selected = layer_data.data.general.send_layer_data_to_api.val.value;
          } else {
            this.d_layerSettingsOperation.settings.send_layer_data_to_api.selected = 'no';
          }
          if (layer_data.data.general.sending_data_api_data_model_sentence_template && layer_data.data.general.sending_data_api_data_model_sentence_template.val && layer_data.data.general.sending_data_api_data_model_sentence_template.val.value) {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.selected = layer_data.data.general.sending_data_api_data_model_sentence_template.val.value;
          } else {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_sentence_template.selected = 'no';
          }
          if (layer_data.data.general.sending_data_api_data_model_parametric_html && layer_data.data.general.sending_data_api_data_model_parametric_html.val && layer_data.data.general.sending_data_api_data_model_parametric_html.val.value) {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.selected = layer_data.data.general.sending_data_api_data_model_parametric_html.val.value;
          } else {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_html.selected = 'no';
          }
          if (layer_data.data.general.sending_data_api_data_model_parametric_wdmr && layer_data.data.general.sending_data_api_data_model_parametric_wdmr.val && layer_data.data.general.sending_data_api_data_model_parametric_wdmr.val.value) {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.selected = layer_data.data.general.sending_data_api_data_model_parametric_wdmr.val.value;
          } else {
            this.d_layerSettingsOperation.settings.sending_data_api_data_model_parametric_wdmr.selected = 'no';
          }
          if (layer_data.data.general.sending_data_api_type && layer_data.data.general.sending_data_api_type.val && layer_data.data.general.sending_data_api_type.val.value) {
            this.d_layerSettingsOperation.settings.sending_data_api_type.selected = layer_data.data.general.sending_data_api_type.val.value;
          } else {
            this.d_layerSettingsOperation.settings.sending_data_api_type.selected = '';
          }
          if (layer_data.data.general.api_sending_button_name && layer_data.data.general.api_sending_button_name.val !== undefined) {
            this.d_layerSettingsOperation.settings.api_sending_button_name.selected = layer_data.data.general.api_sending_button_name.val;
          } else {
            this.d_layerSettingsOperation.settings.api_sending_button_name.selected = '';
          }
        }
        this.d_layerSettingsOperation.show = true;
      } else {
        alert('Lütfen bir katman seçiniz');
      }
    },
    f_layerSettingsOperation: function () {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let query = 'layer_id=' + layer_id;
      if (this.d_layerSettingsOperation.settings.data_record_layer_type.selected === 'persistent') {
        this.d_layerSettingsOperation.settings.temporary_data_delete_loop.selected = 'delete_var';
        this.d_layerSettingsOperation.settings.send_delete_mail_type.selected = 'delete_var';
        this.d_layerSettingsOperation.settings.delete_mail_count.selected = 'delete_var';
        this.d_layerSettingsOperation.settings.temporary_data_minutes.selected = 'delete_var';
      }
      for (let i in this.d_layerSettingsOperation.settings) {
        if (['api_sending_button_name'].indexOf(i) === -1) {
          if (this.d_layerSettingsOperation.settings[i].selected !== '') {
            query += '&' + i + '=' + this.d_layerSettingsOperation.settings[i].selected;
          }
        }
      }
      let data = {
        'api_sending_button_name': this.d_layerSettingsOperation.settings.api_sending_button_name.selected
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman ayarları güncelleniyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_settings_operation(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {} else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_openNewWdmrModal: function () {
      this.d_newWdmrModal.show = true;
    },
    f_addNewWdmrToThisLayer: function () {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let wdmr_data = {
        'data': {}
      };
      if (this.d_newWdmrModal.label) {
        wdmr_data.label = this.d_newWdmrModal.label;
      }
      let data = {
        'wdmr': wdmr_data
      };
      let query = 'layer_id=' + layer_id;
      if (this.d_selectedParentWdmrInfo.id) {
        query += '&parent_wdmr_id=' + this.d_selectedParentWdmrInfo.id;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri kaydı oluşturuluyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_wdmr_record(query, data)
        .then(resp => {
          /*
            {
                "status_code": "<string> | 3000",
                "status_message": "<string> | Successfully responded",
                "w_id": "<string> | new created or updated wdmr’s id",
                "cas": "<string> | new created or updated wdmr’s cas"
            }
          */
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            let wdmr_id = resp.data.w_id;
            let url_show_iframe = this.f_createUrlIframeRecord(wdmr_id, '', 'manuel');
            this.d_wisdomData = {
              'wdmr_id': wdmr_id,
              'show': true,
              'wisdom_op': 'edit',
              'url_iframe': url_show_iframe
            };

          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_editWdmrData: function (new_screen = '') {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let wdmr_id = this.d_wdmrRecordList.list[this.d_wdmrRecordList.selected_index].w_id;
      let model_id = this.d_wdmrEditNewWebTab.model_id;
      let schema_id = this.d_wdmrEditNewWebTab.schema_id;
      let url_show_iframe = this.f_createUrlIframeRecord(wdmr_id, '', 'manuel', model_id, schema_id);
      if (new_screen === 'yes') {
        window.open(url_show_iframe, '_blank');
      } else {
        this.d_wisdomData = {
          'wdmr_id': wdmr_id,
          'show': true,
          'wisdom_op': 'edit',
          'url_iframe': url_show_iframe
        };
      }
      // console.log(this.d_wisdomData);
    },
    f_deleteUserFromSelected: function (user_ind) {
      this.d_searchUser.selected_user_list.splice(user_ind, 1);
      this.$forceUpdate();
    },
    f_openLayerUserAssignModal: function () {
      this.d_searchUser.list = [];
      this.d_searchUser.selected_user_list = [];
      this.d_searchUser.search_text = '';
      this.d_searchUser.show = true;
    },
    f_addToUserList: function (user) {
      let det = false;
      for (let i in this.d_searchUser.selected_user_list) {
        if (this.d_searchUser.selected_user_list[i].username === user.username) {
          det = true;
          break;
        }
      }
      if (!det) {
        this.d_searchUser.selected_user_list.push(user);
        this.$forceUpdate();
      }
    },
    f_searchUserList: function () {
      this.d_searchUser.list = [];
      let data = {
        'search': this.d_searchUser.search_text
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı araması yapılıyor. Lütfen bekleyiniz.' } });
      WmanagerService.user_search_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            this.d_searchUser.list = resp.data.list;
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_saveLayerData: function (layer_id = '') {
      /*
        this.d_layerDataRecord.layer_data =
        {
            "perm": "can_add_edit_wdm",
            "layer_list": [
            {
                "wdm_version": "2.0",
                "layer_name": "Lokal sistemi",
                "layer_type": "wdmr",
                "wdmr_id": "1",
                "wdm_type": "wdm233",
                "wdm_label": "Sistem dökümanı"
            },
            {
                "wdm_version": "2.0",
                "layer_name": "",
                "layer_type": "wdm",
                "wdmr_id": "",
                "wdm_type": "wdm22",
                "wdm_label": "Kullanıcı bilgileri"
            }],
            "similar_layer": []
        }
      */
      for (let i in this.d_layerDataRecord.layer_data.layer_list) {
        let det = false;
        for (let k in this.d_userWdmList.list) {
          if (this.d_layerDataRecord.layer_data.layer_list[i].wdm_type === this.d_userWdmList.list[k].value) {
            this.d_layerDataRecord.layer_data.layer_list[i].wdm_label = this.d_userWdmList.list[k].label;
            det = true;
            break;
          }
        }
        if (!det) {
          alert('Wdm başlığı bulunamadı. Wdm izin listenizde olmayabilir. Lütfen kontrol ediniz.');
          return;
        }
      }
      // console.log(this.d_layerDataRecord);
      // return;
      let query = '';
      if (layer_id) {
        query += 'layer_id=' + layer_id;
      }
      let data = {
        'layer_data': this.d_layerDataRecord.layer_data
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman kaydediliyor. Lütfen bekleyiniz.' } });
      WmanagerService.layer_create(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            this.f_getUserLayerList();
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_deleteLayerItem: function (layer_item_ind) {
      this.d_layerDataRecord.layer_data.layer_list.splice(layer_item_ind, 1);
      this.$forceUpdate();
    },
    f_addNewLayerItem: function () {
      let new_layer_list_item = {
        'wdm_version': '',
        'layer_name': '',
        'layer_type': 'wdm',
        'wdmr_id': '',
        'wdm_type': '',
        'wdm_label': ''
      };
      this.d_layerDataRecord.layer_data.layer_list.push(new_layer_list_item);
      this.$forceUpdate();
    },
    f_selectUserWdmVersion: function (wdm_item_ind, version_ind) {
      this.d_optionData = '';
      this.d_userWdmList.selected_index = wdm_item_ind;
      this.d_userWdmList.version_index = version_ind;
      let wdm_item = this.d_userWdmList.list[wdm_item_ind];
      let version_data = this.d_userWdmList.list[wdm_item_ind]['list'][version_ind];
      let query = 'wdm_type=' + wdm_item.value + '&owner=' + wdm_item.owner_type + '&version=' + version_data.version;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı WDM datası getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.user_wdm_data_get(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            this.d_optionData = {};
            this.d_optionData[wdm_item.value] = resp.data.wdm_data;
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_getUserWdmList: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı WDM listesi getiriliyor. Lütfen bekleyiniz.' } });
      WmanagerService.user_wdm_list()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            this.d_userWdmList.list = resp.data.wdm_list;
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_addNewLayer: function () {
      this.d_layerDataRecord.layer_data.data.general.layer;
    },
    f_openLayerRecordModal: function () {
      this.d_layerDataRecord.show = true;
    },
    f_copySelectedLayerForNewLayer: function () {
      this.d_layerDataRecord.layer_data = {
        'perm': '',
        'layer_list': [],
        'similar_layer': []
      }
      for (let i in this.d_layerInformation.data.data.general.layer.list) {
        let layer_list_item = this.d_layerInformation.data.data.general.layer.list[i];
        let new_layer_list_item = {
          'wdm_version': '',
          'layer_name': '',
          'layer_type': 'wdm',
          'wdmr_id': '',
          'wdm_type': '',
          'wdm_label': ''
        };
        try {
          new_layer_list_item.wdm_version = layer_list_item.wdm_version.val.value;
        } catch (err) {}
        try {
          new_layer_list_item.layer_name = layer_list_item.layer_name.val.label;
        } catch (err) {}
        try {
          new_layer_list_item.wdmr_id = layer_list_item.layer_name.val.value.split('_')[1];
        } catch (err) {}
        try {
          new_layer_list_item.layer_type = layer_list_item.layer_type.val.value;
        } catch (err) {}
        try {
          new_layer_list_item.wdm_type = layer_list_item.wdm_type.val.value;
        } catch (err) {}
        try {
          new_layer_list_item.wdm_label = layer_list_item.wdm_type.val.label;
        } catch (err) {}
        this.d_layerDataRecord.layer_data.layer_list.push(new_layer_list_item);
      }
      this.d_layerDataRecord.show = true;
    },
    f_dateFormat: function (date) {
      if (date) {
        let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
        return x;
      } else {
        return "";
      }
    },
    f_completeWdmrSave: function () {
      this.d_wisdomData.show = false;
    },
    f_goToLink: function (url) {
      window.open(url, '_blank');
    },
    f_createUrlIframeShow: function (wdmr_id) {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let url_iframe_show = this.WDMR_LINKS['wdmr_data_show'] + 'layer_id=' + layer_id + '&wdmr_id=' + wdmr_id + '&wisdom=' + this.d_wdmrToken.access_token + '&modsiw=' + this.d_wdmrToken.refresh_token;
      // console.log('url_iframe_show : ', url_iframe_show);
      return url_iframe_show;
    },
    f_createUrlIframeRecord: function (wdmr_id = '', parent_wdmr_id = '', record_type = 'manuel', model_id = '', schema_id = '') {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let url_iframe_record = this.WDMR_LINKS['wdmr_data_record'] + 'layer_id=' + layer_id;
      if (wdmr_id) {
        url_iframe_record += '&wdmr_id=' + wdmr_id;
      }
      if (model_id) {
        url_iframe_record += '&model_id=' + model_id;
      }
      if (schema_id) {
        url_iframe_record += '&schema_id=' + schema_id;
      }
      if (parent_wdmr_id) {
        url_iframe_record += '&parent_wdmr_id=' + parent_wdmr_id;
      }
      url_iframe_record += '&record_type=' + record_type;
      url_iframe_record += '&wisdom=' + this.d_wdmrToken.access_token + '&modsiw=' + this.d_wdmrToken.refresh_token;
      // console.log('url_iframe_recordu : ', url_iframe_record);
      return url_iframe_record;
    },
    f_updateUrlIframe: function (obj, url_iframe_var, new_url) {
      obj[url_iframe_var] = '';
      setTimeout(function () {
        obj[url_iframe_var] = new_url;
      }.bind(this), 1000)
    },
    f_selectWdmrRecord: function (x_ind) {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      this.d_wdmrRecordList.selected_index = x_ind;
      let wdmr_id = this.d_wdmrRecordList.list[x_ind].w_id;
      /*
        if (this.d_layerInformation.data && this.d_layerInformation.data.general.generate_wdmr_token && this.d_layerInformation.data.general.generate_wdmr_token.val && this.d_layerInformation.data.general.generate_wdmr_token.val.value) {
          if (this.d_layerInformation.data.general.generate_wdmr_token.val.value === 'yes') {

          }
        }
      */
      // For every selection, we select the wdmr as parent. But it is not every time. This approach must be differently designed.
      this.f_updateUrlIframe(this.d_wdmrRecordList, 'url_show_iframe', this.f_createUrlIframeShow(wdmr_id));
      this.f_updateUrlIframe(this.d_wdmrRecordList, 'url_record_iframe', this.f_createUrlIframeRecord(wdmr_id, '', 'manuel'));
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri modeli bilgileri. Lütfen bekleyiniz.' } });
      let query = 'layer_id=' + layer_id;
      query += '&wdmr_id=' + wdmr_id;
      WmanagerService.get_layer_wdmr(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status_code === "3000") {
            this.d_wdmrRecordList.data = resp.data.wdmr;
          } else {
            console.log(resp);
          }
        }), resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        };
    },
    f_addParentWdmrInfo: function () {
      if (this.d_wdmrRecordList.selected_index !== '') {
        let wdmr_id = this.d_wdmrRecordList.list[this.d_wdmrRecordList.selected_index].w_id;
        this.d_selectedParentWdmrInfo.id = wdmr_id;
        this.d_selectedParentWdmrInfo.layer_info = JSON.parse(JSON.stringify(this.d_layerList[this.d_selectedLayerIndex]));
        this.d_selectedParentWdmrInfo.layer_data = JSON.parse(JSON.stringify(this.d_layerInformation.data));
      } else {
        alert('Lütfen öncelikle bir veri modeli seçimi yapınız.');
      }
    },
    f_calculateBottomTabStyle: function () {
      let height = '';
      let overflow_x = '';
      if (this.d_fullScreenPage) {
        height = window.innerHeight - 50;
        overflow_x = 'hidden';
      } else {
        height = window.innerHeight - 300;
        overflow_x = 'hidden';
        // console.log('height: ', height);
      }
      if (this.device.isMobile) {
        return 'margin-bottom: 0px; border: none;';
      } else {
        return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
      }
    },
    f_calculateSubTabStyle: function () {
      let height = '';
      let overflow_x = '';
      if (this.d_fullScreenPage) {
        height = window.innerHeight - 50;
        overflow_x = 'hidden';
      } else {
        height = window.innerHeight - 350;
        overflow_x = 'hidden';
        // console.log('height: ', height);
      }
      if (this.device.isMobile) {
        return 'margin-bottom: 0px; border: none;';
      } else {
        return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
      }
    },
    f_getRecordedWdmrList: function () {
      // console.log("this.f_getRecordedWdmrList ");
      if (this.d_selectedLayerIndex !== '') {
        let start_type = this.d_pagination.start_type;
        let limit = this.d_pagination.perpage.toString();
        let offset = this.d_pagination.start.toString();
        let layer_type = 'operation';
        let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
        let get_wdmr_data = '0';
        let query = 'layer_id=' + layer_id + '&get_wdmr_data=' + get_wdmr_data;
        if (limit) {
          query += '&limit=' + limit;
        }
        if (offset) {
          query += '&offset=' + offset;
        }
        if (start_type) {
          query += '&start_type=' + start_type;
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler hazırlanıyor. Lütfen bekleyiniz' } });
        WmanagerService.get_layer_wdmr_list(query)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              this.d_wdmrRecordList.selected_index = '';
              this.d_wdmrRecordList.list = resp.data.list;
              this.d_wdmrRecordList.total_count = resp.data.count;
            }
          }, resp => {
            console.log(resp);
          });
      }
    },
    f_addNewWdmr: function (data_type) {
      ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
      this.d_wisdomData.show = true;
    },
    f_saveLayerWdmr: function () {
      // While opening wdmr layer, we control the last layer which must be a wdmr.
      let layer_data = [];
      for (let i in this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list) {
        let lay_data = this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list[i];
        let x = '';
        if (lay_data.layer_name) {
          x = {
            "w_id": lay_data.layer_name.val.value,
            "type": lay_data.wdm_type.val.value
          };
        } else {
          x = {
            "type": lay_data.wdm_type.val.value
          };
        }
        // We are putting last layer wdm version only
        if (parseInt(i) === this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list.length - 1) {
          x.vers = lay_data.wdm_version.val.value
        }
        if (this.d_wisdomData.wisdom_op === 'edit') {
          x.w_edit_id = this.d_wisdomData.wisdom_data.id
        }
        layer_data.push(x);
      }
      ClsWdmrManage.save_layer_wdmr(this.d_wisdomData, layer_data)
        .then(resp => {
          console.log(resp);
        }, resp => {
          alert('error ', resp);
        });
    },
    f_addNewLayer: function () {
      if (this.d_newLayerModal.type === 'wdmr') {
        //
      }
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm224_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_createEligiblePermList: function () {
      let x = this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list.length - 1;
      if (this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list[x].layer_type.val.value === 'wdm') {
        this.d_allPermissions = [
          { 'label': 'wdm ekleme & düzenleme', 'value': 'can_add_edit_wdm' },
          { 'label': 'wdm görüntüleme', 'value': 'can_view_wdm' }
        ];
      } else { // wdmr
        this.d_allPermissions = [
          { 'label': 'wdm mimarisi dizayn edebilme', 'value': 'can_design_wdm_architecture' },
          { 'label': 'wdm ekleme & düzenleme', 'value': 'can_add_edit_wdm' },
          { 'label': 'wdm görüntüleme', 'value': 'can_view_wdm' }
        ];
      }
    },
    f_addNewWdmLayer: function () {
      if (this.d_selectedLayerIndex !== '') {
        this.f_createEligiblePermList();
        this.d_newLayerModal.type = 'wdm';
        this.d_newLayerModal.show = true;
        this.d_newLayerModal.selected_perm = '';
        this.d_newLayerModal.selected_wdm = '';
      } else {
        let msg = 'Henüz katman seçmediniz. Lütfen öncelikle bir katman seçimi yapınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Katman ekleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addNewWdmrLayer: function () {
      if (this.d_selectedLayerIndex !== '') {
        let x = this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list.length - 1;
        if (this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list[x].layer_type.val.value === 'wdm') {
          let last_layer = this.d_permList.layer_perms[this.d_selectedLayerIndex].layer.list[x];
          let buck = '';
          let wdm_key = '';
          for (let i in this.d_permList.wdm_perms) {
            if (this.d_permList.wdm_perms[i].type === last_layer.wdm_type.val.value && this.d_permList.wdm_perms[i].version === last_layer.wdm_version.val.value) {
              buck = this.d_permList.wdm_perms[i].owner === 'wisdom' ? 'option' : 'hospital';
              wdm_key = this.d_permList.wdm_perms[i].value;
            }
          }
          if (buck && wdm_key) {
            let data = {
              'key': wdm_key,
              'bucket': buck
            };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Wdm hazırlanıyor. Lütfen bekleyiniz' } });
            GlobalService.get_one_cb_document(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp.data.status === 'success') {
                  this.d_wisdomData.option_data[resp.data.result.parent] = resp.data.result;
                  this.d_wisdomData.data_type = resp.data.result.parent;
                  ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, resp.data.result.parent)
                  this.d_wisdomData.show = true;
                } else {
                  console.log('error ', resp.data.message);
                }
              });
          } else {
            let msg = 'Bu wdm izin listenizde bulunamadı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Wdmr ekleme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        } else {
          let msg = 'Wdmr katmanı ekleyebilmeniz için lütfen bir wdm katmanı seçiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Katman ekleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Henüz katman seçmediniz. Lütfen öncelikle bir katman seçimi yapınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Katman ekleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_getUserLayerList: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman listesi hazırlanıyor. Lütfen bekleyiniz' } });
      WmanagerService.layer_list()
        .then(resp => {
          if (resp.data.status_code === "3000") {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_layerList = resp.data.layer_list;
            // console.log("this.$route.query.layer_id ", this.$route.query.layer_id);
            if (this.$route.query && this.$route.query.layer_id !== undefined) {
              for (let i in this.d_layerList) {
                if (this.d_layerList[i].id === this.$route.query.layer_id) {
                  this.f_selectLayer(parseInt(i));
                  break;
                }
              }
            }
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_getLayerWdm: function (layer_id) {
      let query = 'layer_id=' + layer_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman veri modeli hazırlanıyor. Lütfen bekleyiniz' } });
      WmanagerService.get_layer_wdm(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === "3000") {
            let wdm_type = this.d_layerList[this.d_selectedLayerIndex].layer_wdm_id;
            this.d_layerWdm[wdm_type] = resp.data.layer_wdm;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_selectLayer: function (layer_ind) {
      this.d_wdmrRecordList = {
        'url_show_iframe': '',
        'data': '',
        'show': false,
        'list': [],
        'selected_index': '',
        'total_count': 0
      };
      let layer_id = this.d_layerList[layer_ind].id;
      let query = 'layer_id=' + layer_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman bilgileri hazırlanıyor. Lütfen bekleyiniz' } });
      WmanagerService.layer_data_get(query)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === "3000") {
            if (!this.$route.query || (this.$route.query && this.$route.query.layer_id !== layer_id)) {
              let route_data = {
                'query': {
                  'layer_id': layer_id
                }
              }
              this.$router.push(route_data);
            }
            this.d_layerInformation.data = resp.data.layer_data;
            /*
              this.d_wdmrRecordList.total_count = 0;
              try {
                this.d_wdmrRecordList.total_count = parseInt(this.d_layerInformation.data.data.general.total_wdmr_count.val);
              } catch (err) {}
            */
            this.d_selectedLayerIndex = layer_ind;
            if (['can_add_edit_wdm', 'can_view_wdm'].indexOf(this.d_layerInformation.data.data.general.perm.val.value) !== -1) {
              this.f_getLayerWdm(layer_id);
            }
            this.d_pagination = {
              'start_type': 'begin', // end or begin
              'start': 0,
              'end': 49,
              'current': 0,
              'perpage': 50
            };
            this.f_getRecordedWdmrList();
            // this.f_calculateTreeOfThisSelectedLayer();
            this.f_getLayerTreeList();
            this.f_prepareAlgorithmRule();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_getLayerTreeList: function () {
      let layer_id = this.d_layerList[this.d_selectedLayerIndex].id;
      let query = 'main_layer_id=' + layer_id + '&list_type=layer_tree';
      WmanagerService.layer_list(query)
        .then(resp => {
          if (resp.data.status_code === "3000") {
            this.d_layerTree = resp.data.list_tree_data;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_calculateTreeOfThisSelectedLayer: function () {
      let eligible_layer_list = [];
      eligible_layer_list.push(this.d_layerList[this.d_selectedLayerIndex].id);
      for (let i in this.d_layerList) {
        if (this.d_layerList[i].upper_client_layer_id && eligible_layer_list.indexOf(this.d_layerList[i].upper_client_layer_id) !== -1) {
          // console.log('this.d_layerList[i].upper_client_layer_id : ', this.d_layerList[i].upper_client_layer_id);
          eligible_layer_list.push(this.d_layerList[i].id);
          let upper_client_layer_data = '';
          let child_layer_data = JSON.parse(JSON.stringify(this.d_layerList[i]));
          child_layer_data.list = [];
          for (let layer_index in this.d_layerList) {
            if (this.d_layerList[layer_index].id === this.d_layerList[i].upper_client_layer_id) {
              upper_client_layer_data = JSON.parse(JSON.stringify(this.d_layerList[layer_index]));
              upper_client_layer_data.list = [];
            }
          }
          let turning_data = {
            'is_found': false,
            'upper_client_layer_data': upper_client_layer_data,
            'child_layer_data': child_layer_data
          };
          this.f_turnInLayerTree(turning_data, this.d_layerTree['list']);
          // console.log("turning_data['is_found'] : ", turning_data['is_found']);
          if (!turning_data['is_found']) {
            this.d_layerTree['list'].push(upper_client_layer_data);
            this.d_layerTree['list'][this.d_layerTree['list'].length - 1].list.push(child_layer_data);
            // console.log(this.d_layerTree['list']);
          }
        }
      }
    },
    f_turnInLayerTree: function (turning_data, list_data) {
      for (let li_index in list_data) {
        if (list_data[li_index].id === turning_data['upper_client_layer_data'].id) {
          list_data[li_index].list.push(turning_data['child_layer_data']);
          break;
        } else {
          if (list_data[li_index].list && list_data[li_index].list.length > 0) {
            this.f_turnInLayerTree(turning_data, list_data[li_index].list);
          }
        }
      }
    },
    f_openLayerList: function () {
      if (this.d_layerList.length > 0) {
        this.d_showLayerList = true;
        setTimeout(function () {
          let div_el = document.getElementById('user-layer-list');
          if (div_el) {
            div_el.focus();
          }
        }.bind(this), 500);

      } else {
        let msg = 'Katman listesi boş';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Katman listesi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
      /*
      setTimeout(function () {
        let x = document.getElementById('data_model_list');
        if (x) {
          x.focus();
        }
      }.bind(this), 500);
      */
    },
    f_getUserLayerListOld: function () {
      let data = {
        'wdm22_id': this.d_wdm22.id
      };
      WdmService.get_perm_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_permList = resp.data.result;
          } else {
            console.log('errorr get_wdm_layer : ', resp.data.message);
          }
        });
    },
    f_getWdmrTreeList: function () {
      let data = {
        'parent_wdm': this.d_treeListData.parent,
        'child_wdm': this.d_treeListData.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list_remote(data)
        .then(resp => {
          if (resp.data.status === "success") {
            if (resp.data.result.status === 'success') {
              this.d_treeListData.list = resp.data.result.result.list;
            }
          } else {
            console.log('errorr get_wdmr_tree_list_remote : ', resp.data.message);
          }
        });
    },
    f_getInfoHtml: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe max-width="100%" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        let starting_text = txt.substring(0, txt.indexOf(replace_text));
        if (starting_text.length > 150) {
          starting_text = starting_text.substring(0, 150) + '...';
        }
        return starting_text + iframeMarkup;
      } else {
        // console.log(txt)
        if (txt.length > 300) {
          return txt.substring(0, 300) + '...';
        } else {
          return txt;
        }
      }
    },
    f_goToDocumentDetails: function (wdmr231_key) {
      let route_data = {
        'name': 'documenttree',
        'path': 'documenttree',
        'query': {
          'wdmr231': wdmr231_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    },
    f_getWdmrParamInfo: function () {
      this.d_searchText = '';
      this.d_searchDataSet.text = '';
      this.d_selectedTabParamDetails = 'param_info';
      this.d_selectedParamInfo = '';
      this.d_dataset = [];
      this.d_tablePagination.start = 0;
      this.d_tablePagination.current = 1;
      this.d_selectedCells = [];
      try {
        localStorage.removeItem('param_info_options');
      } catch (err) {}
      if (this.d_wdmParameters.selected_index_data.index_list.length > 0) {
        let query = 'layer_id=' + this.d_layerList[this.d_selectedLayerIndex].id;
        let data = {
          'param': this.d_wdmParameters.selected_index_data.index_list
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Katman veri modeli hazırlanıyor. Lütfen bekleyiniz' } });
        WmanagerService.get_layer_wdm_param_info(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === "3000") {
              this.d_selectedParamInfo = resp.data.param_info;
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_getWdmrParamInfoOptions: function () {
      try {
        localStorage.removeItem('param_info_options');
      } catch (err) {}
      if (this.d_wdmParameters.selected_index_data.index_list.length > 0) {
        let query = 'layer_id=' + this.d_layerList[this.d_selectedLayerIndex].id;
        let data = {
          'param': this.d_wdmParameters.selected_index_data.index_list
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Parametre seçenekleri hazırlanıyor. Lütfen bekleyiniz' } });
        WmanagerService.get_layer_wdm_param_info_options(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            // console.log(resp.data);
            if (resp.data.status_code === "3000") {
              if (resp.data.param_info.options) {
                this.d_selectedParamInfo.options = resp.data.param_info.options;
                let options = resp.data.param_info.options;
                if (options && options.length > 0) {
                  localStorage.setItem('param_info_options', JSON.stringify(options));
                  this.f_prepareTableDataset(options);
                }
              }
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_prepareTableDataset: function (list_data = '') {
      // console.log("this.d_tablePagination.start ", this.d_tablePagination.start);
      // console.log("this.d_tablePagination.end", this.d_tablePagination.end);
      if (list_data === '') {
        list_data = JSON.parse(localStorage.getItem('param_info_options'));
      }
      if (list_data && list_data.length > 0) {
        this.d_dataset = [];
        for (let row_ind in list_data) {
          let row_data = list_data[row_ind];
          if (this.d_searchText) {
            if (row_data.label.toLocaleLowerCase().indexOf(this.d_searchText) !== -1) {
              let row_new = { 'count': '', 'label': '' };
              if (row_data.count !== undefined) {
                row_new.count = row_data.count;
              }
              if (row_data.label !== undefined) {
                row_new.label = row_data.label;
              }
              this.d_dataset.push(row_new);
            }
          } else {
            if (parseInt(row_ind) >= this.d_tablePagination.start && parseInt(row_ind) < this.d_tablePagination.end) {
              // First we prepare column list.
              // we prepare dataste now.
              let row_new = { 'count': '', 'label': '' };
              if (row_data.count !== undefined) {
                row_new.count = row_data.count;
              }
              if (row_data.label !== undefined) {
                row_new.label = row_data.label;
              }
              this.d_dataset.push(row_new);
            }
            if (parseInt(row_ind) == this.d_tablePagination.end) {
              break;
            }
          }
        }
      }
      this.f_datasetShowFunc();
    },
    f_dataSetStyle: function (x) {
      let height = window.innerHeight - x;
      let overflow_x = 'auto';
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.d_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selectedCells.indexOf(select) === -1) {
          this.d_selectedCells.push(select);
        } else {
          this.d_selectedCells.splice(this.d_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        //
      } else if (type === 'col') {
        for (let r in this.d_dataset) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_showWTableCellData: function (row, col_value) {
      this.d_showWTableCellData.text = row[col_value];
      this.d_showWTableCellData.show = true;
    },
    f_datasetShowFunc: function () {
      this.d_datasetShow = false;
      setTimeout(function () {
        this.d_datasetShow = true;
      }.bind(this), 500);
    },
    f_turnInfinityInParameters: function (param_list, search_list, turning_data) {
      // param_list contains {'param_order': [], 'parameters': {}}
      // search_list = ['general', 'selected_diagnosis']
      // turning_data = {'completed': false, 'param_value_list': ['general', 'selected_diagnosis']}
      if (!turning_data.completed) {
        for (let pr_index in param_list['param_order']) {
          let param_value = param_list['param_order'][pr_index];
          if (parseInt(pr_index) === 0) {
            turning_data.param_value_list.push(param_value);
          } else {
            turning_data.param_value_list[turning_data.param_value_list.length - 1] = param_value;
          }
          if (turning_data.param_value_list.join() === search_list.join()) {
            this.d_selectedParamData = param_list.parameters[param_value];
            turning_data.completed = true;
            /*
              this.upper_parameters = param_list.parameters;
              this.upper_param_order = param_list.param_order;
              this.MotherObjectListParam = {};
              if (this.selectedParamData.type === 'object_list_options') {
                if (this.selectedParamData.mother_param && this.upper_parameters[this.selectedParamData.mother_param]) {
                  this.MotherObjectListParam = this.upper_parameters[this.selectedParamData.mother_param];
                }
              }
              if (this.selectedParamData.option_search) {
                this.parameter_option_method = 'option_search';
              } else {
                this.parameter_option_method = 'manuel';
              }
            */
          }
          if (param_list.parameters[param_value].param_order && param_list.parameters[param_value].param_order.length > 0 && !turning_data.completed) {
            this.f_turnInfinityInParameters(param_list.parameters[param_value], search_list, turning_data);
            turning_data.param_value_list.splice(turning_data.param_value_list.length - 1, 1);
          }
        }
      }
    },
  },
  watch: {
    'd_pagination.current': function () {
      if (this.d_pagination.current <= 0) {
        this.d_pagination.current = 1;
      }
      this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
      this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
      this.f_getRecordedWdmrList();
    },
    'd_wdmParameters.selected_index_data.index_list': function () {
      this.f_getWdmrParamInfo();
      let wdm_data = this.d_layerWdm[this.d_layerList[this.d_selectedLayerIndex].layer_wdm_id];
      for (let pg_index in wdm_data.param_group.param_order) {
        let pg_value = wdm_data.param_group.param_order[pg_index];
        let turning_data = { 'param_value_list': [pg_value], 'completed': false };
        this.f_turnInfinityInParameters(wdm_data[pg_value], this.d_wdmParameters.selected_index_data.index_list, turning_data, 1);
        if (turning_data.completed) {
          break;
        }
      }
    },
    'd_tablePagination.current': function () {
      this.d_tablePagination.start = (this.d_tablePagination.current - 1) * this.d_tablePagination.perpage;
      this.d_tablePagination.end = (this.d_tablePagination.perpage * this.d_tablePagination.current) - 1;
      if (this.d_tablePagination.current > 1) {
        this.d_tablePagination.end += 1;
      } else if (this.d_tablePagination.current === 1) {
        this.d_tablePagination.end = this.d_tablePagination.perpage;
      }
      this.f_prepareTableDataset();
    },
    'd_searchText': function () {
      // console.log(this.d_searchText);
      this.d_tablePagination['current'] = 1;
      this.d_tablePagination['start'] = 0;
      this.f_prepareTableDataset();
    },
    'd_searchDataSet.text': function () {
      clearTimeout(this.d_searchDataSet.timeout);
      let vm = this;
      this.d_searchDataSet.timeout = setTimeout(function () {
        vm.d_searchText = vm.d_searchDataSet.text;
      }, 1000);
    }
  }
};

</script>

<style type="text/css">
#user-search-list-row:hover {
  background-color: #92c9ea;
}

#user-search-list-selected-row:hover {
  background-color: #e29494;
}

</style>

